@import './breakpoints';

.content {
    flex-grow: 1;
    height: 100vh;
}

.dealerManagerContainer {
    padding-bottom: 32px;
    max-width: 900px !important;
}

.dealerSearchContainer {
    padding-bottom: 32px;
    max-width: 1700px !important;

    tr.Mui-selected {
        background-color: rgb(73, 218, 141) !important;
    }

    tr.MuiTableRow-hover:hover {
        background-color: #3f51b56e !important;
    }
    
    .MuiButton-root:hover {
        background-color: #0a0add;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #49da8d !important;
    }
}

.header {
    padding: 5px 0px; 
}

.menuAndSave {
    display: flex;
}

.title {
    flex-grow: 1;
    position: static;
    text-align: center;
    order: 2;
}

.menuAndSave {
    display: flex;
    order: 1;
}

.updatedBy {
    font-size: 11px;
}

.loginStatus {
    display: block;
    float: right;
    text-align: right;
    order: 4;
}

.home {
    padding-top: 90px;
}

.button {
    color: white !important;
}

a, a:link, a:visited, a:focus, a:hover, a:active {
    color:#3f51b5;
    text-decoration:none;
}

.alertSuccess {
    background-color: green;
    display: flex;
    align-items: center;
}

.alertError {
    background-color: red;
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;
}

.mappingError {
    margin-top: 35%;
}

.brandingExperienceMessage {
    color: #0000008a;
    font-size: 12px;
}

.brandingExperienceImage {
    max-width: 400px;
    max-height: 200px;
}

.dealerManagerContainer {
    .dealerManagerTabs {
        position: fixed; 
        left: 0;
        right: 0;
        background-color: #fafafa; 
        z-index: 11;
        padding: 0;
    }
    
    @media screen and (min-width: $screen-mobile-portrait-width) {
        .dealerManagerTabs {
            top: 11.3rem;
        } 
        .globalFieldsWrapper {
            margin-top: 14.8rem;
        }
    }

    @media screen and (min-width: $screen-mobile-iphonex-portrait-width) {
        .dealerManagerTabs {
            top: 8.8rem;
        }
        .globalFieldsWrapper {
            margin-top: 12.1rem;
        }
    }

    @media screen and (min-width: $screen-mobile-landscape-width) {
        .dealerManagerTabs {
            top: 8.9rem;
        }
        .globalFieldsWrapper {
            margin-top: 12.5rem;
        }
    }
    
    /* Desktop responsive very small window */
    @media screen and (min-width: 500px) {
        .dealerManagerTabs {
            top: 6.8rem;
        }
        .globalFieldsWrapper {
            margin-top: 10.3rem;
        }
    }

    @media screen and (min-width: $screen-mobile-iphone5SE-6-7-8-plus-landscape-width) {
        .dealerManagerTabs {
            top: 6.8rem;
        }
        .globalFieldsWrapper {
            margin-top: 10.3rem;
        }
    }

    /* Tablet and desktop responsive to mid-size windows */
    @media screen and (min-width: 874px) {
        .dealerManagerTabs {
            top: 5.6rem;
        }
        .globalFieldsWrapper {
            margin-top: 9.1rem;
        }
    }

    @media screen and (min-width: 898px) {
        .dealerManagerTabs {
            top: 4.5rem;
        }
        .globalFieldsWrapper {
            margin-top: 8rem;
        }
    }
}
// mid-size screen
@media screen and (max-width: $screen-tablet-portrait-width) {
    .header {
        // child mui-wrapper needs to flex-wrap
        > div {
            flex-wrap: wrap;   
        }
    }
    .loginStatus {
        flex-grow: 1;
        > div {
            display: flex;
            align-items: baseline;
            float: right;
        }
    }
    .title {
        display: block;
        width: 100%;
        order: 5;
        padding: 0 !important;
        margin: 0 !important;
    }
    #DealershipInfo {
        padding-top: 9em;
    }
}

// mobile-landscape
@media screen and (max-width: $screen-mobile-landscape-width) {
    #DealershipInfo {
        padding-top: 10em;
    }
}

// material-table aditions
.MuiInputBase-root-938 { // makes select input in edit row full width
    width: 100% !important;
}
